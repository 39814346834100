@import 'src/base';

.rfid-card-creation {
    .rfid-card-creation-form {
        padding: 1.5rem;
        .loading-spiner {
            margin: 20px 0;
            margin-bottom: 20px;
            padding: 30px 50px;
            text-align: center;
            border-radius: 4px;
        }
        .rfid-card-creation-form-user {
            .rfid-card-creation-form-user-switch {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .rfid-card-creation-form-user-switch-toggle {
                    margin: 5px;
                }
            }
        }
        .rfid-card-creation-form-select {
            height: 48px;
            line-height: 48px;
            align-items: center;
            .ant-select-selector {
                border-radius: 0.5rem;
                align-items: center;
                height: 48px;
                line-height: 48px;
                // background-color: blue;
                .ant-select-selection-item {
                    line-height: 40px;
                    align-self: baseline;
                    height: 40px;
                    // background-color: red;
                    border-radius: 0.4rem;
                    
                }
            }
        }
        .rfid-card-creation-form-btn {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            height: auto;
            width: auto;
        }
    }
}

